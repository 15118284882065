<template>
  <div class="col-12 col-lg-12 c g">
    <div class="card">
        <div class="card-header">
            <h5>
                اعدادات الواتس الذكي
            </h5>
        </div>
        <div class="card-body">
            <div class="form-group">
              <span for="">JWT</span>
              <input type="text"
                class="form-control form-control-lg" v-model="jwt">
            </div>
            <div class="form-group">
              <span for="">رقم الجهاز</span>
              <input type="text"
                class="form-control form-control-lg" v-model="device_id">
            </div>
            <hr>
            <div class="form-group">
              <label for="">ايقاف توزيع الخيام</label>
              <select class="form-control" v-model="stop_arrange_camps">
                <option value="no">ايقاف</option>
                <option value="yes">تشغيل</option>
              </select>
            </div>
            <div class="form-group">
              <label for="">ايقاف توزيع الحافلات</label>
              <select class="form-control" v-model="stop_arrange_busses">
                <option value="no">ايقاف</option>
                <option value="yes">تشغيل</option>
              </select>
            </div>
            <div class="border g" style="margin-bottom: 5px">
              <h5>
                <i class="fa fa-briefcase"></i>
                الوظائف <button class="btn btn-sm btn-success" style="border-radius: 50%; padding: 3px;" @click="addJob()">
                  <i class="fa fa-plus"></i>
                </button>
              </h5>
              <div class="col-12 table-responsive">
                <table class="table table-sm table-hover table-bordered">
                  <thead>
                    <th>
                      الوظيفة
                    </th>
                    <th>
                      المهام الموكلة
                    </th>
                    <th>
                      العدد المطلوب
                    </th>
                    <th>
                      وقت العمل
                    </th>
                    <th>
                      الراتب
                    </th>
                    <th>
                      المكافأة
                    </th>
                    <th>
                      #
                    </th>
                  </thead>
                  <tbody>
                    <tr v-for="job in jobs" :key="job.code">
                      <td style="padding: 0px">
                        <input type="text" v-model="job.title" placeholder="اكتب هنا..." class="form-control form-control-sm" style="border-radius: 0px; height: 35.5px; width: 150px">
                      </td>
                      <td style="padding: 0px">
                        <textarea v-model="job.rules" placeholder="اكتب هنا..." class="form-control form-control-sm" style="border-radius: 0px; width: 200px" rows="1"></textarea>
                      </td>
                      <td style="padding: 0px">
                        <input type="number" v-model="job.max" placeholder="اكتب هنا..." class="form-control form-control-sm" style="border-radius: 0px; height: 35.5px; width: 100px">
                      </td>
                      <td style="padding: 0px">
                        <input type="text" v-model="job.time" placeholder="اكتب هنا..." class="form-control form-control-sm" style="border-radius: 0px; height: 35.5px">
                      </td>
                      <td style="padding: 0px">
                        <input type="number" v-model="job.salary" placeholder="اكتب هنا..." class="form-control form-control-sm" style="border-radius: 0px; height: 35.5px">
                      </td>
                      <td style="padding: 0px">
                        <input type="number" v-model="job.bouns" placeholder="اكتب هنا..." class="form-control form-control-sm" style="border-radius: 0px; height: 35.5px">
                      </td>
                      <td style="padding: 0px">
                        <button @click="deleteJob(job.code)" class="btn btn-sm btn-outline-danger" style="border-radius: 0px; height: 35.5px">
                          <i class="fa fa-trash"></i>
                        </button>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <div class="form-group">
              <label for="">الاقسام (افصل بينهم بـ<span class="btn-sm  btn btn-outline-primary" style="padding: 5px">,</span>)</label>
              <input type="text"
                class="form-control" v-model="sections" placeholder="كذا,كذا,كذا">
            </div>
            <div class="form-group">
              <label for="">صيغة العقد #1</label>
              <textarea class="form-control" v-model="contract_header" rows="3"></textarea>
            </div>
            <div class="form-group">
              <label for="">صيغة العقد #2</label>
              <textarea class="form-control" v-model="contract_body" rows="3"></textarea>
            </div>
            <div class="form-group">
              <label for="">صيغة العقد #3</label>
              <textarea class="form-control" v-model="contract_footer" rows="3"></textarea>
            </div>
            <div class="form-group">
              <label for="">تصنيفات البلاغات (افصل بينهم بـ<span class="btn-sm  btn btn-outline-primary" style="padding: 5px">,</span>)</label>
              <input class="form-control" v-model="ticket_categories">
            </div>
        </div>
        <div class="card-footer text-center">
            <button class="btn btn-primary" @click="save()">
                <i class="fa fa-save"></i>
                حفظ الاعدادات
            </button>
        </div>
    </div>
  </div>
</template>

<script>
export default {
    data(){
        return {
            user: JSON.parse(localStorage.getItem("user")),
            device_id: '',
            jwt: '',
            stop_arrange_camps: '',
            stop_arrange_busses: '',
            sections: "",
            jobs: [],
            contract_body: "",
            contract_header: "",
            contract_footer: "",
            ticket_categories: ""
        }
    },
    created(){
        if(!checkPer('*')){
            this.$router.push('/pers')
            return;
        }
        var g = this;
        $.post(api + '/admin/web/settings', {
            jwt: g.user.jwt
        }).then(function(r){
            if(!r.response.jobs.includes("[")){
              r.response.jobs = '[]';
            }
            g.jwt = r.response.jwt;
            g.device_id = r.response.device_id;
            g.stop_arrange_camps = r.response.stop_arrange_camps;
            g.stop_arrange_busses = r.response.stop_arrange_busses;
            g.sections = r.response.sections;
            g.jobs = JSON.parse(r.response.jobs);
            g.contract_header = r.response.contract_header;
            g.contract_body = r.response.contract_body;
            g.contract_footer = r.response.contract_footer;
            g.ticket_categories = r.response.ticket_categories
        })
    },
    methods: {
        deleteJob(id){
            this.jobs = this.jobs.filter(function(x){
              if(x.code == id){
                return false;
              }
              return x;
            })
            var g = this;
            g.jobs = JSON.parse(JSON.stringify(g.jobs))
        },
        addJob(){
          var g = this;
          g.jobs.push({
            code: Math.random().toString().split(".")[1]
          })
          g.jobs = JSON.parse(JSON.stringify(g.jobs))
        },
        save(){
            var g = this;
            $.post(api + '/admin/web/settings/save', {
                jwt: g.user.jwt,
                device_id: g.device_id,
                stop_arrange_camps: g.stop_arrange_camps,
                stop_arrange_busses: g.stop_arrange_busses,
                jwtv: g.jwt,
                sections: g.sections,
                jobs: JSON.stringify(g.jobs),
                contract_body: g.contract_body,
                contract_header: g.contract_header,
                contract_footer: g.contract_footer,
                ticket_categories: g.ticket_categories
            }).then(function(r){
               location.reload()
            })
        }
    }
}
</script>

<style>

</style>